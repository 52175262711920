/**
 * 

I den nationella svenska blockpolitiken brukar Miljöpartiet på senare år räknas till det rödgröna blocket eller vänsterblocket,[9] och har sedan riksdagsvalet 2014 ingått i regeringen tillsammans med Socialdemokraterna. Redan i valrörelsen inför riksdagsvalet 2010 samarbetade partiet med Socialdemokraterna och Vänsterpartiet som De rödgröna. År 2011 gjorde partiet dock en uppgörelse om migrationsfrågor med den borgerliga Regeringen Reinfeldt. Mellan 1998 och 2006 hade Miljöpartiet ett parlamentariskt samarbete med den socialdemokratiska Regeringen Persson och Vänsterpartiet.
 */


const initState = {
    latestUpdate: '2020-05',
    opinionInstitutes: ['sifo', 'novus', 'ipsos', 'demoskop/inizio', 'sentio', 'TEMO'],
    parties: {
        'V': { 
          name: "vänsterpartiet",
          color: '#ee151f',
          logo: process.env.PUBLIC_URL + '/images/vansterpartiet.png',
          party_leader_image: process.env.PUBLIC_URL + '/images/jonas-v.png',
          homepage: 'https://www.vansterpartiet.se/',
          register_url: 'https://vansterpartietweb.azurewebsites.net/#/newregister',
          heroText: {
            breadText: 'Vänsterpartiet (V) är ett svenskt socialistiskt och feministiskt parti på ekologisk grund. På partiets valsedlar anges partibeteckningen Vänsterpartiet. Partiledare är sedan 2012 Jonas Sjöstedt.',
            paragraphs: [
              {title: null,       text: 'Vänsterpartiet antog sitt nuvarande namn 1990 som ersatte det tidigare Vänsterpartiet Kommunisterna som använts från 1967, dessförinnan Sveriges Kommunistiska Parti från 1921 och Sveriges socialdemokratiska vänsterparti från 1917. Vänsterpartiet har som socialdemokraterna sitt ursprung i den socialistiska arbetarrörelsen men varit betydligt mindre än socialdemokraterna som länge också var negativa till samarbete med Vänsterpartiet och den kommunistiska ideologi partiet stod för fram till 1980-talet.'},
              {title: null,       text: 'Mellan 1998 och 2006 agerade Vänsterpartiet tillsammans med Miljöpartiet stödparti till den Socialdemokratiska minoritetsregeringen. Inför riksdagsvalet i Sverige 2010, mellan december 2008 och den 26 oktober 2010, samarbetade partiet tillsammans med Socialdemokraterna och Miljöpartiet som De rödgröna. Från riksdagsvalet 2014 till 2019 samarbetade Vänsterpartiet med Regeringen Löfven, bestående av Socialdemokraterna och Miljöpartiet, om budgeten. Detta samarbete upphörde med januariavtalet.'},
              {title: null,       text: 'Partiet är det femte största i riksdagen, med 28 mandat av 349, efter att ha fått 8,00 procent av rösterna i riksdagsvalet 2018.[8] Vänsterpartiet har sitt starkaste stöd i Göteborgs kommuns (14,00 % valet 2018, 9,35 % valet 2014), Stockholms kommuns (13,12 % valet 2018, 7,73 % valet 2014) och Västerbottens läns (12,69 % valet 2018, 10,96 % valet 2014) riksdagsvalkretsar.'},
              {title: null,       text: 'I Europaparlamentsvalet 2019 erhöll Vänsterpartiet 6,80 procentenheter av väljarnas röster och fick därmed ett mandat i parlamentet. I parlamentet är Vänsterpartiet en del av Gruppen Europeiska enade vänstern/Nordisk grön vänster. Partiet är inte medlem av Europeiska vänsterpartiet.'},
              {title: null,       text: 'Partiet har inte längre någon nomineringsgrupp i Svenska kyrkan, utan har ersatts av Vänsterns religiösa nätverk (VRG) som har nomineringsgruppen Vänstern i Svenska kyrkan (ViSK).'},
            ]
          },
          currentPercent: 8.8,
          riksdagsval: {
            '1917': 8.1,
            '1920': 6.4,
            '1921': 4.6,
            '1924': 3.6,
            '1928': 6.4,
            '1932': 3.0,
            '1936': 3.3,
            '1940': 3.5,
            '1944': 10.3,
            '1948': 6.3,
            '1952': 4.3,
            '1956': 5.0,
            '1958': 3.4,
            '1960': 4.5,
            '1964': 5.2,
            '1968': 3.0,
            '1970': 4.8,
            '1973': 5.3,
            '1976': 4.8,
            '1979': 5.6,
            '1982': 5.6,
            '1985': 5.4,
            '1988': 5.8,
            '1991': 4.5,
            '1994': 6.2,
            '1998': 12.0,
            '2002': 8.4,
            '2006': 5.9,
            '2010': 5.6,
            '2014': 5.7,
            '2018': 8.0,
          },
          opinionsundersökningar: {
            '2006-09': 7.2,
            '2006-10': 5.9,
            '2006-11': 5.7,
            '2006-12': 5.8,
            '2007-01': 5.6,
            '2007-02': 5.0,
            '2007-03': 5.9,
            '2007-04': 4.7,
            '2007-05': 4.4,
            '2007-06': 5.9, 
            '2007-07': 5.7, 
            '2007-08': 5.6,
            '2007-09': 5.8,
            '2007-10': 5.6,
            '2007-11': 5.1,
            '2007-12': 5.0,
            '2008-01': 4.8,
            '2008-02': 5.6,
            '2008-03': 5.1,
            '2008-04': 6.1,
            '2008-05': 6.0,
            '2008-06': 5.6,
            '2008-07': 6.2,
            '2008-08': 5.3,
            '2008-09': 5.1,
            '2008-10': 7.4,
            '2008-11': 5.3,
            '2008-12': 7.2,
            '2009-01': 6.0,
            '2009-02': 5.6,
            '2009-03': 7.0,
            '2009-04': 7.3,
            '2009-05': 7.0,
            '2009-06': 5.4,
            '2009-07': 5.4,
            '2009-08': 5.3,
            '2009-09': 6.8,
            '2009-10': 4.8,
            '2009-11': 4.6,
            '2009-12': 5.3,
            '2010-01': 4.6,
            '2010-02': 4.8,
            '2010-03': 5.2,
            '2010-04': 5.6,
            '2010-05': 5.2,
            '2010-06': 5.2,
            '2010-07': 4.7,
            '2010-08': 4.4,
            '2010-09': 6.1,
            '2010-10': 5.4, //Demoskop
            '2010-11': 5.0,
            '2010-12': 6.1,
            '2011-01': 5.2,
            '2011-02': 4.5,
            '2011-03': 5.2,
            '2011-04': 4.4,
            '2011-05': 4.9,
            '2011-06': 5.2,
            '2011-07': 3.8,
            '2011-08': 6.1,
            '2011-09': 5.9,
            '2011-10': 4.6,
            '2011-11': 6.7,
            '2011-12': 4.2,
            '2012-01': 5.9,
            '2012-02': 9.3,
            '2012-03': 6.3,
            '2012-04': 6.5,
            '2012-05': 5.7,
            '2012-06': 6.9,
            '2012-07': 4.4,
            '2012-08': 6.1, //Ipsos
            '2012-09': 5.8, //Demoskop
            '2012-10': 5.9,
            '2012-11': 4.7,
            '2012-12': 5.1,
            '2013-01': 5.9,
            '2013-02': 5.8,
            '2013-03': 6.2,
            '2013-04': 5.9,
            '2013-05': 5.3,
            '2013-06': 6.4,
            '2013-07': 7.8,
            '2013-08': 6.9, //Ipsos
            '2013-09': 7.1, //Demoskop
            '2013-10': 6.6,
            '2013-11': 6.4,
            '2013-12': 8.3,
            '2014-01': 6.5,
            '2014-02': 7.3,
            '2014-03': 8.4,
            '2014-04': 8.2,
            '2014-05': 8.7,
            '2014-06': 8.1,
            '2014-07': 7.2,
            '2014-08': 6.5,
            '2014-09': 6.8,
            '2014-10': 6.0, //Medelvärde
            '2014-11': 6.3,
            '2014-12': 5.2,
            '2015-01': 6.0,
            '2015-02': 6.3,
            '2015-03': 6.3,
            '2015-04': 6.6,
            '2015-05': 6.8,
            '2015-06': 6.6,
            '2015-07': 6.8, //Demoskop
            '2015-08': 6.9, //Medelvärde
            '2015-09': 6.5,
            '2015-10': 6.9,
            '2015-11': 6.7,
            '2015-12': 7.1,
            '2016-01': 7.0,
            '2016-02': 7.2,
            '2016-03': 7.3,
            '2016-04': 7.6,
            '2016-05': 7.9,
            '2016-06': 7.4,
            '2016-07': 7.6, //Demoskop
            '2016-08': 8.3, //Medelvärde
            '2016-09': 7.9,
            '2016-10': 7.9,
            '2016-11': 7.9,
            '2016-12': 7.8,
            '2017-01': 8.0,
            '2017-02': 7.7,
            '2017-03': 7.8,
            '2017-04': 7.4,
            '2017-05': 7.7,
            '2017-06': 7.9,
            '2017-07': 8.0,
            '2017-08': 7.6,
            '2017-09': 7.2,
            '2017-10': 7.7,
            '2017-11': 7.4,
            '2017-12': 7.5,
            '2018-01': 7.4,
            '2018-02': 8.2,
            '2018-03': 8.0,
            '2018-04': 8.0,
            '2018-05': 8.6,
            '2018-06': 9.1,
            '2018-07': 9.6,
            '2018-08': 10.0,
            '2018-09': 9.3,
            '2018-10': 7.8,
            '2018-11': 8.0,
            '2018-12': 7.9,
            '2019-01': 8.8,
            '2019-02': 9.7,
            '2019-03': 9.5,
            '2019-04': 9.3,
            '2019-05': 9.2,
            '2019-06': 8.8,
            '2019-07': 8.9,
            '2019-08': 9.2,
            '2019-09': 8.9,
            '2019-10': 9.0,
            '2019-11': 8.9,
            '2019-12': 9.5,
            '2020-01': 10.2,
            '2020-02': 10.7,
            '2020-03': 10.8,
            '2020-04': 8.9,
            '2020-05': 8.8,
          }
        },
        'S': { 
          name: "socialdemokraterna", 
          color: '#eb1934',
          logo: process.env.PUBLIC_URL + '/images/socialdemokraterna.png',
          party_leader_image: process.env.PUBLIC_URL + '/images/stefan-s.png',
          homepage: 'https://www.socialdemokraterna.se',
          register_url: 'https://www.socialdemokraterna.se/bli-medlem',
          heroText: {
            breadText: 'Sveriges socialdemokratiska arbetareparti (SAP), vanligen Socialdemokraterna (S), är ett socialdemokratiskt politiskt parti i Sverige. På partiets valsedlar anges partibeteckningen Arbetarepartiet – Socialdemokraterna. Partiordförande sedan 27 januari 2012 är Stefan Löfven.',
            paragraphs: [
              {title: null,       text: 'Socialdemokraterna har sitt ursprung i den socialistiska arbetarrörelsen och är dess främsta politiska gren. Partiet innehade regeringsmakten oavbrutet från 1936 till 1976. Partiet sitter efter valet 2014 i en koalitionsregering med Miljöpartiet, efter att ha varit i opposition sedan valet 2006.'},
              {title: null,       text: 'Partiet har stått för marknadsekonomi kombinerat med politiska regleringar och statligt ägande ett samhälle med allmän välfärd genom en stark, skattefinansierad offentlig sektor. I och med 1980- och 1990-talens tredje vägens politik har partiet rört sig närmare den så kallade politiska mitten. Partiets officiella ideologi är socialdemokrati. Socialdemokraterna betonar vikten av fördelningspolitik samt principen av var och en efter förmåga, åt var och en efter behov.'},
              {title: null,       text: 'Partiet är Sveriges största sett till antalet medlemmar och valresultat, både i riksdagen och i kyrkomötet. Socialdemokraterna har sitt starkaste stöd i storstädernas utanförskapsområden samt i norra Sverige.'},
              {title: null,       text: 'I Europaparlamentsvalet 2019 erhöll Socialdemokraterna 23,48 procentenheter av väljarnas röster och fick därmed fem mandat i parlamentet. I parlamentet är Socialdemokraterna en del av Gruppen Progressiva förbundet av socialdemokrater i Europaparlamentet (S&D). Partiet är medlem av Europeiska socialdemokratiska partiet.'},
              {title: null,       text: 'Partiet är också en nomineringsgrupp i Svenska kyrkan under beteckningen Arbetarpartiet - socialdemokraterna.'},
              {title: 'Riksnivå', text: 'Partiet har sitt huvudkontor på Sveavägen 68 i Stockholm. Här sammanträder partistyrelsen och dess verkställande utskott (VU) som är högsta beslutande organ mellan kongresserna, som hålls vartannat år. LO:s ordförande har alltid en adjungerad plats i både partistyrelsen och VU.'},
              {title: 'Länsnivå', text: 'Partiet är uppdelat i 26 partidistrikt. Partidistrikten utgör partiets högsta organisatoriska enhet på regional nivå. Distrikten styrs av en distriktsstyrelse som väljs på distriktets kongress. Distriktets kongress utgör partidistriktets högsta beslutande organ. Partidistrikten fastställer partiets kandidatlistor för val till landstingsfullmäktige och Svenska kyrkans valda beslutsorgan.'},
            ]
          },
          currentPercent: 30.5,
          riksdagsval: {
            '1911': 28.5,
            '1914-03': 30.1,
            '1914-09': 36.4,
            '1917': 31.1,
            '1920': 29.7,
            '1921': 36.2,
            '1924': 41.1,
            '1928': 37.0,
            '1932': 41.7,
            '1936': 45.9,
            '1940': 53.8,
            '1944': 46.7,
            '1948': 46.1,
            '1952': 46.1,
            '1956': 44.6,
            '1958': 46.2,
            '1960': 47.8,
            '1964': 47.3,
            '1968': 50.1,
            '1970': 45.3,
            '1973': 43.6,
            '1976': 42.7,
            '1979': 43.2,
            '1982': 45.6,
            '1985': 44.7,
            '1988': 43.2,
            '1991': 37.6,
            '1994': 45.3,
            '1998': 36.4,
            '2002': 39.8,
            '2006': 35.0,
            '2010': 30.7,
            '2014': 31.0,
            '2018': 28.3,
          },
          opinionsundersökningar: {
            '2006-09': 34.5,
            '2006-10': 37.0,
            '2006-11': 39.9,
            '2006-12': 40.8,
            '2007-01': 41.0,
            '2007-02': 41.4,
            '2007-03': 42.5,
            '2007-04': 46.3,
            '2007-05': 45.0,
            '2007-06': 41.9,
            '2007-07': 41.7,
            '2007-08': 41.6,
            '2007-09': 44.6,
            '2007-10': 44.3,
            '2007-11': 43.7,
            '2007-12': 46.2,
            '2008-01': 46.7,
            '2008-02': 46.8,
            '2008-03': 43.7,
            '2008-04': 42.5,
            '2008-05': 44.8,
            '2008-06': 44.1,
            '2008-07': 44.0,
            '2008-08': 43.9,
            '2008-09': 45.4,
            '2008-10': 45.7,
            '2008-11': 40.9,
            '2008-12': 40.0,
            '2009-01': 40.1,
            '2009-02': 38.1,
            '2009-03': 37.5,
            '2009-04': 33.5,
            '2009-05': 33.7,
            '2009-06': 34.0,
            '2009-07': 35.1,
            '2009-08': 36.3,
            '2009-09': 34.0,
            '2009-10': 34.6,
            '2009-11': 35.0,
            '2009-12': 34.3,
            '2010-01': 34.9,
            '2010-02': 35.2,
            '2010-03': 33.7,
            '2010-04': 35.1,
            '2010-05': 32.5,
            '2010-06': 30.0,
            '2010-07': 30.2, //Senito
            '2010-08': 30.3,
            '2010-09': 30.7, //Medel
            '2010-10': 29.0,
            '2010-11': 28.9,
            '2010-12': 28.2,
            '2011-01': 27.8,
            '2011-02': 30.1,
            '2011-03': 29.3,
            '2011-04': 28.8,
            '2011-05': 30.3,
            '2011-06': 29.2,
            '2011-07': 32.5,
            '2011-08': 32.5,
            '2011-09': 31.0,
            '2011-10': 28.2,
            '2011-11': 26.6,
            '2011-12': 26.1,
            '2012-01': 23.9,
            '2012-02': 24.8,
            '2012-03': 29.7,
            '2012-04': 34.1,
            '2012-05': 35.5,
            '2012-06': 35.7,
            '2012-07': 35.4,
            '2012-08': 34.8,
            '2012-09': 33.1,
            '2012-10': 32.6,
            '2012-11': 33.1,
            '2012-12': 32.0,
            '2013-01': 32.7,
            '2013-02': 31.9,
            '2013-03': 32.3,
            '2013-04': 32.7,
            '2013-05': 32.9,
            '2013-06': 33.5,
            '2013-07': 33.7,
            '2013-08': 34.4, //Ipsos
            '2013-09': 31.7,
            '2013-10': 32.2,
            '2013-11': 31.1,
            '2013-12': 33.1,
            '2014-01': 34.7,
            '2014-02': 34.5,
            '2014-03': 32.7,
            '2014-04': 33.0,
            '2014-05': 32.2,
            '2014-06': 29.1,
            '2014-07': 31.5,
            '2014-08': 29.9,
            '2014-09': 29.5,
            '2014-10': 29.3,
            '2014-11': 28.7,
            '2014-12': 30.4,
            '2015-01': 31.5,
            '2015-02': 30.1,
            '2015-03': 29.8,
            '2015-04': 27.3,
            '2015-05': 26.3,
            '2015-06': 26.1,
            '2015-07': 27.0, //Demoskop
            '2015-08': 25.1,
            '2015-09': 25.3,
            '2015-10': 25.1,
            '2015-11': 24.9,
            '2015-12': 24.4,
            '2016-01': 23.9,
            '2016-02': 23.5,
            '2016-03': 25.4,
            '2016-04': 25.1,
            '2016-05': 25.1,
            '2016-06': 25.2,
            '2016-07': 26.0, //Demoskop
            '2016-08': 25.2,
            '2016-09': 25.4,
            '2016-10': 25.0,
            '2016-11': 25.5,
            '2016-12': 25.9,
            '2017-01': 25.7,
            '2017-02': 25.7,
            '2017-03': 26.0,
            '2017-04': 26.6,
            '2017-05': 27.1,
            '2017-06': 26.7,
            '2017-07': 24.8,
            '2017-08': 27.8,
            '2017-09': 28.7,
            '2017-10': 28.5,
            '2017-11': 28.5,
            '2017-12': 27.1,
            '2018-01': 27.6,
            '2018-02': 26.8,
            '2018-03': 26.7,
            '2018-04': 26.3,
            '2018-05': 24.9,
            '2018-06': 24.3,
            '2018-07': 23.4,
            '2018-08': 23.9,
            '2018-09': 26.2,
            '2018-10': 29.4,
            '2018-11': 29.2,
            '2018-12': 29.7,
            '2019-01': 29.2,
            '2019-02': 27.7,
            '2019-03': 26.7,
            '2019-04': 26.4,
            '2019-05': 26.3,
            '2019-06': 25.9,
            '2019-07': 25.6,
            '2019-08': 25.6,
            '2019-09': 24.6,
            '2019-10': 24.7,
            '2019-11': 24.4,
            '2019-12': 23.7,
            '2020-01': 23.2,
            '2020-02': 23.6,
            '2020-03': 23.8,
            '2020-04': 29.1,
            '2020-05': 30.5,
          }
        },
        'MP': { 
          name: "miljöpartiet", 
          color: '#00a238',
          logo: process.env.PUBLIC_URL + '/images/miljopartiet.png',
          party_leader_image: process.env.PUBLIC_URL + '/images/mp-leaders.png',
          homepage: 'https://www.mp.se',
          register_url: 'https://www.mp.se/bli-medlem',
          heroText: {
            breadText: 'Miljöpartiet de gröna (MP), vanligen Miljöpartiet, är ett politiskt parti i Sverige, bildat 1981. Partiet växte fram främst ur miljörörelsen och fredsrörelsen och var ursprungligen en reaktion mot kärnkraftsomröstningen, blockpolitik och vad man uppfattade som bristande deltagardemokrati och jämställdhet i andra partier. Dess tillkomst inspirerades av andra gröna partier som det tyska Die Grünen som anammar den gröna ideologin. På partiets valsedlar anges partibeteckningen Miljöpartiet de gröna.',
            paragraphs: [
              {title: null,       text: 'Miljöpartiet har en kvinnlig och en manlig partiledare, benämnda språkrör, vilka (sedan maj 2019) är Isabella Lövin och Per Bolund. Partiet är det minsta i riksdagen, med 16 mandat av 349, efter att ha fått 4,41 procent av rösterna i riksdagsvalet 2018. I Europaparlamentsvalet 2019 fick Miljöpartiet 2 mandat, efter att ha fått 11,52 procent av rösterna. I parlamentet är Miljöpartiet en del av Gruppen De gröna/Europeiska fria alliansen (G/EFA). Partiet är medlem av det Europeiska gröna partiet. Miljöpartiet har sitt starkaste stöd i storstadsregionerna Stockholms och Göteborgs kommuners riksdagsvalkretsar, samt bland unga och högutbildade väljare.'},
              {title: null,       text: 'I den nationella svenska blockpolitiken brukar Miljöpartiet på senare år räknas till det rödgröna blocket eller vänsterblocket,[9] och har sedan riksdagsvalet 2014 ingått i regeringen tillsammans med Socialdemokraterna. Redan i valrörelsen inför riksdagsvalet 2010 samarbetade partiet med Socialdemokraterna och Vänsterpartiet som De rödgröna. År 2011 gjorde partiet dock en uppgörelse om migrationsfrågor med den borgerliga Regeringen Reinfeldt. Mellan 1998 och 2006 hade Miljöpartiet ett parlamentariskt samarbete med den socialdemokratiska Regeringen Persson och Vänsterpartiet.'},
              {title: null,       text: 'På sin hemsida (2018) lyfter Miljöpartiet fram fyra områden som särskilt viktiga. För det första miljöpolitiken, där Miljöpartiet vill skydda skogar, hav och den biologiska mångfalden. Men även att Miljöpartiet vill satsa på ekologisk mat, förnybar energi, hållbara transporter och grön ekonomi. För det andra klimatpolitiken, där Miljöpartiet vill att Sverige ökar takten för att bli fossilfritt. För det tredje skola och utbildning, där Miljöpartiet vill satsa på tidiga insatser, bättre villkor och högre lön för lärarna samt ökad jämlikhet mellan och i skolorna. Och för det fjärde migration och lika rätt, där Miljöpartiet framhåller sin vision om en värld utan gränser "där alla kan flytta, men ingen tvingas fly'},
            ]
          },
          currentPercent: 3.8,
          riksdagsval: {
            '1982': 1.7,
            '1985': 1.5,
            '1988': 5.4,
            '1991': 3.4,
            '1994': 5.0,
            '1998': 4.5,
            '2002': 4.6,
            '2006': 5.2,
            '2010': 7.3,
            '2014': 6.9,
            '2018': 4.4,
          },
          opinionsundersökningar: {
            '2006-09': 5.6,
            '2006-10': 5.7,
            '2006-11': 5.3,
            '2006-12': 5.3,
            '2007-01': 5.2,
            '2007-02': 6.7,
            '2007-03': 5.8,
            '2007-04': 5.4,
            '2007-05': 5.8,
            '2007-06': 5.6,
            '2007-07': 5.8,
            '2007-08': 6.1,
            '2007-09': 5.6,
            '2007-10': 5.3,
            '2007-11': 6.8,
            '2007-12': 6.0,
            '2008-01': 5.6,
            '2008-02': 6.0,
            '2008-03': 6.4,
            '2008-04': 6.2,
            '2008-05': 5.6,
            '2008-06': 6.3,
            '2008-07': 6.0,
            '2008-08': 5.6,
            '2008-09': 5.8,
            '2008-10': 6.2,
            '2008-11': 6.9,
            '2008-12': 5.6,
            '2009-01': 6.0,
            '2009-02': 6.3,
            '2009-03': 5.1,
            '2009-04': 6.4,
            '2009-05': 6.5,
            '2009-06': 8.0,
            '2009-07': 8.0,
            '2009-08': 8.1,
            '2009-09': 8.0,
            '2009-10': 7.8,
            '2009-11': 9.1,
            '2009-12': 9.8,
            '2010-01': 9.1,
            '2010-02': 9.7,
            '2010-03': 10.2,
            '2010-04': 10.6,
            '2010-05': 8.8,
            '2010-06': 9.3,
            '2010-07': 9.3, //Senito
            '2010-08': 10.3,
            '2010-09': 7.3,
            '2010-10': 9.6,
            '2010-11': 9.1,
            '2010-12': 8.9,
            '2011-01': 9.6,
            '2011-02': 9.3,
            '2011-03': 10.6,
            '2011-04': 10.1,
            '2011-05': 10.5,
            '2011-06': 11.8,
            '2011-07': 10.6,
            '2011-08': 9.7,
            '2011-09': 10.4,
            '2011-10': 10.5,
            '2011-11': 12.6,
            '2011-12': 11.5,
            '2012-01': 14.6,
            '2012-02': 11.6,
            '2012-03': 10.9,
            '2012-04': 9.5,
            '2012-05': 10.2,
            '2012-06': 7.7,
            '2012-07': 9.1,
            '2012-08': 9.9, //Sifo
            '2012-09': 8.9,
            '2012-10': 7.7,
            '2012-11': 8.0,
            '2012-12': 8.3,
            '2013-01': 7.3,
            '2013-02': 8.5,
            '2013-03': 9.6,
            '2013-04': 9.3,
            '2013-05': 9.2,
            '2013-06': 10.0,
            '2013-07': 8.9,
            '2013-08': 10.9, //Sifo
            '2013-09': 10.1,
            '2013-10': 10.1,
            '2013-11': 11.8,
            '2013-12': 8.3,
            '2014-01': 11.1,
            '2014-02': 9.6,
            '2014-03': 12.5,
            '2014-04': 10.7,
            '2014-05': 10.2,
            '2014-06': 13.0,
            '2014-07': 13.6,
            '2014-08': 9.8,
            '2014-09': 10.1,
            '2014-10': 7.9,
            '2014-11': 7.6,
            '2014-12': 6.6,
            '2015-01': 6.3,
            '2015-02': 6.8,
            '2015-03': 6.4,
            '2015-04': 6.6,
            '2015-05': 6.4,
            '2015-06': 6.3,
            '2015-07': 7.1, //Demoskop
            '2015-08': 6.6,
            '2015-09': 6.1,
            '2015-10': 6.0,
            '2015-11': 6.1,
            '2015-12': 5.6,
            '2016-01': 5.4,
            '2016-02': 5.4,
            '2016-03': 5.6,
            '2016-04': 5.2,
            '2016-05': 4.2,
            '2016-06': 4.4,
            '2016-07': 3.9,
            '2016-08': 3.7,
            '2016-09': 4.3,
            '2016-10': 4.1,
            '2016-11': 4.1,
            '2016-12': 4.4,
            '2017-01': 4.5,
            '2017-02': 4.2,
            '2017-03': 4.1,
            '2017-04': 4.0,
            '2017-05': 3.7,
            '2017-06': 3.8,
            '2017-07': 4.3,
            '2017-08': 4.2,
            '2017-09': 4.0,
            '2017-10': 3.8,
            '2017-11': 4.5,
            '2017-12': 3.9,
            '2018-01': 4.0,
            '2018-02': 3.8,
            '2018-03': 4.0,
            '2018-04': 4.1,
            '2018-05': 4.1,
            '2018-06': 4.3,
            '2018-07': 4.4,
            '2018-08': 5.5,
            '2018-09': 4.6,
            '2018-10': 4.5,
            '2018-11': 4.0,
            '2018-12': 4.1,
            '2019-01': 3.8,
            '2019-02': 3.7,
            '2019-03': 3.9,
            '2019-04': 4.3,
            '2019-05': 4.7,
            '2019-06': 5.2,
            '2019-07': 4.7,
            '2019-08': 5.1,
            '2019-09': 4.7,
            '2019-10': 4.7,
            '2019-11': 4.9,
            '2019-12': 4.7,
            '2020-01': 4.3,
            '2020-02': 4.4,
            '2020-03': 4.2,
            '2020-04': 3.9,
            '2020-05': 3.8,
          }
        },
        'M': { 
          name: "moderaterna", 
          color: '#000094',
          logo: process.env.PUBLIC_URL + '/images/moderaterna.png',
          party_leader_image: process.env.PUBLIC_URL + '/images/ulf-m.png',
          homepage: 'https://moderaterna.se',
          register_url: 'https://moderaterna.se/bli-medlem',
          heroText: {
            breadText: 'Moderata samlingspartiet (M), vanligen Moderaterna, är ett liberalkonservativt politiskt parti i Sverige. Moderaterna är Sveriges näst största parti sett till antal mandat i riksdagen. Partiledare är sedan 1 oktober 2017 Ulf Kristersson.',
            paragraphs: [
              {title: null,       text: 'Moderata samlingspartiet antog sitt nuvarande namn 1969 som ersatte det tidigare Högerpartiet som använts från 1952, och som grundades som Allmänna valmansförbundet 1904. På partiets valsedlar anges partibeteckningen Moderaterna. Från valrörelsen 2006 till och med april 2019 betecknade partiet sig som Nya Moderaterna; detta var dock inte ett officiellt namn.'},
              {title: null,       text: 'Partiet har sedan 1979 blivit det största borgerliga partiet i alla riksdagsval. Partiet har sedan ombildningen 1969 tagit två statsministerposter, av Carl Bildt mellan 1991 och 1994 och Fredrik Reinfeldt mellan 2006 och 2014, samt suttit i regering vid två tillfällen under Thorbjörn Fälldin. Från och med 2004 ingick Moderaterna tillsammans med Centerpartiet, Liberalerna och Kristdemokraterna i den borgerliga koalitionen Alliansen. Efter riksdagsvalet 2018 och det efterföljande januariavtalet menade Kristersson och Kristdemokraternas partiledare Ebba Busch Thor att Alliansen inte längre existerade.'},
              {title: null,       text: 'I Europaparlamentet utgör Moderaterna, tillsammans med Kristdemokraterna, en del av Europeiska folkpartiets grupp (EPP). Partiet har fyra mandat i parlamentet efter att ha fått 16,83 procent av rösterna i Europaparlamentsvalet 2019. Inom Sverige har Moderaterna sitt starkaste stöd i Stockholms läns (26,01 % valet 2018, 32,66 % valet 2014) och Skåne läns södras (23,98 % valet 2018, 28,21 % valet 2014) riksdagsvalkretsar. I riksdagsvalet 2018 fick Moderaterna 19,84 procent, en minskning med 3,49 procentenheter jämfört med valet 2014.'},
              {title: null,       text: 'Alltsedan bildandet har Moderaterna influerats av liberalism[förtydliga][källa behövs]. Under 1980-talet fick partiet en allt mer marknadsliberal framtoning, något som jämte den ursprungligt konservativa värdegrunden låg till grund för partiets nu officiella ideologi liberalkonservatism. När Fredrik Reinfeldt övertog ledarrollen i Moderaterna lanserades Nya Moderaterna och partiet sade sig närma sig den politiska mitten.'},
              {title: null,       text: 'Moderata samlingspartiets ideologi är officiellt liberalkonservatism. Partiet är positivt till marknadsekonomi och kapitalism, och menar att frihet möjliggörs via åtgärder såsom sänkta skatter, privatiseringar och avregleringar. Möjligheten till val av leverantör av varor och tjänster är enligt partiet viktig; detta skall gå hand i hand med en minskad offentlig sektor och ett minskat statligt ägande, liksom minskat engagemang i samhällsekonomin.'},
            ]
          },
          currentPercent: 19.6,
          riksdagsval: {
            '1911': 31.3,
            '1914-03': 37.7,
            '1914-09': 36.5,
            '1917': 24.7,
            '1920': 27.8,
            '1921': 25.8,
            '1924': 26.1,
            '1928': 29.4,
            '1932': 23.1,
            '1936': 17.6,
            '1940': 18.0,
            '1944': 15.9,
            '1948': 12.3,
            '1952': 14.4,
            '1956': 17.1,
            '1958': 19.5,
            '1960': 16.5,
            '1964': 13.7,
            '1968': 12.9,
            '1970': 11.5,
            '1973': 14.3,
            '1976': 15.6,
            '1979': 20.3,
            '1982': 23.6,
            '1985': 21.3,
            '1988': 18.3,
            '1991': 21.9,
            '1994': 22.4,
            '1998': 22.9,
            '2002': 15.2,
            '2006': 26.2,
            '2010': 30.1,
            '2014': 23.3,
            '2018': 19.8,
          },
          opinionsundersökningar: {
            '2006-09': 25.1,
            '2006-10': 26.9,
            '2006-11': 25.2,
            '2006-12': 26.4,
            '2007-01': 26.3,
            '2007-02': 25.2,
            '2007-03': 25.3,
            '2007-04': 24.1,
            '2007-05': 23.8,
            '2007-06': 24.8,
            '2007-07': 25.0,
            '2007-08': 25.3,
            '2007-09': 23.8,
            '2007-10': 23.4,
            '2007-11': 23.1,
            '2007-12': 21.6,
            '2008-01': 21.8,
            '2008-02': 21.7,
            '2008-03': 22.6,
            '2008-04': 24.5,
            '2008-05': 22.0,
            '2008-06': 21.9,
            '2008-07': 22.2,
            '2008-08': 22.4,
            '2008-09': 22.4,
            '2008-10': 21.6,
            '2008-11': 25.7,
            '2008-12': 26.7,
            '2009-01': 26.8,
            '2009-02': 28.8,
            '2009-03': 29.3,
            '2009-04': 32.1,
            '2009-05': 29.3,
            '2009-06': 28.1,
            '2009-07': 27.3,
            '2009-08': 26.7,
            '2009-09': 29.8,
            '2009-10': 29.0,
            '2009-11': 30.2,
            '2009-12': 26.7,
            '2010-01': 29.3,
            '2010-02': 27.7,
            '2010-03': 27.5,
            '2010-04': 28.8,
            '2010-05': 31.8,
            '2010-06': 33.1,
            '2010-07': 30.4, //Senito
            '2010-08': 30.1,
            '2010-09': 30.1,
            '2010-10': 32.4,
            '2010-11': 31.8,
            '2010-12': 34.2,
            '2011-01': 33.9,
            '2011-02': 33.4,
            '2011-03': 30.9,
            '2011-04': 32.5,
            '2011-05': 30.4,
            '2011-06': 29.0,
            '2011-07': 31.2,
            '2011-08': 30.1,
            '2011-09': 32.7,
            '2011-10': 31.1,
            '2011-11': 32.8,
            '2011-12': 34.4,
            '2012-01': 32.9,
            '2012-02': 32.9,
            '2012-03': 28.7,
            '2012-04': 29.1,
            '2012-05': 27.7,
            '2012-06': 27.6,
            '2012-07': 27.0,
            '2012-08': 28.5,
            '2012-09': 28.7,
            '2012-10': 28.8,
            '2012-11': 28.7,
            '2012-12': 28.2,
            '2013-01': 29.3,
            '2013-02': 29.0,
            '2013-03': 27.3,
            '2013-04': 27.9,
            '2013-05': 28.4,
            '2013-06': 28.3,
            '2013-07': 26.2,
            '2013-08': 26.6,
            '2013-09': 25.7,
            '2013-10': 25.0,
            '2013-11': 25.0,
            '2013-12': 25.2,
            '2014-01': 25.4,
            '2014-02': 22.8,
            '2014-03': 24.2,
            '2014-04': 24.5,
            '2014-05': 23.2,
            '2014-06': 21.2,
            '2014-07': 23.9,
            '2014-08': 23.4,
            '2014-09': 21.1,
            '2014-10': 24.3,
            '2014-11': 25.5,
            '2014-12': 25.1,
            '2015-01': 24.0,
            '2015-02': 23.2,
            '2015-03': 23.6,
            '2015-04': 24.8,
            '2015-05': 24.6,
            '2015-06': 24.9,
            '2015-07': 23.1,
            '2015-08': 22.5,
            '2015-09': 21.8,
            '2015-10': 22.0,
            '2015-11': 22.0,
            '2015-12': 21.6,
            '2016-01': 23.1,
            '2016-02': 24.1,
            '2016-03': 24.7,
            '2016-04': 25.4,
            '2016-05': 24.6,
            '2016-06': 24.8,
            '2016-07': 24.3,
            '2016-08': 24.4,
            '2016-09': 23.6,
            '2016-10': 23.0,
            '2016-11': 22.4,
            '2016-12': 21.0,
            '2017-01': 21.8,
            '2017-02': 18.8,
            '2017-03': 16.9,
            '2017-04': 16.8,
            '2017-05': 17.0,
            '2017-06': 16.4,
            '2017-07': 15.4,
            '2017-08': 16.3,
            '2017-09': 16.6,
            '2017-10': 20.1,
            '2017-11': 21.0,
            '2017-12': 22.0,
            '2018-01': 23.2,
            '2018-02': 22.6,
            '2018-03': 22.1,
            '2018-04': 21.7,
            '2018-05': 21.4,
            '2018-06': 19.7,
            '2018-07': 18.8,
            '2018-08': 17.6,
            '2018-09': 18.1,
            '2018-10': 19.4,
            '2018-11': 18.5,
            '2018-12': 18.4,
            '2019-01': 18.2,
            '2019-02': 18.0,
            '2019-03': 17.4,
            '2019-04': 16.6,
            '2019-05': 16.7,
            '2019-06': 18.1,
            '2019-07': 17.0,
            '2019-08': 18.2,
            '2019-09': 19.0,
            '2019-10': 17.9,
            '2019-11': 17.5,
            '2019-12': 17.0,
            '2020-01': 17.5,
            '2020-02': 18.1,
            '2020-03': 18.8,
            '2020-04': 18.8,
            '2020-05': 19.6,
          }
        },
        'L': { 
          name: "liberalerna", 
          color: '#006ab4',
          logo: process.env.PUBLIC_URL + '/images/liberalerna.png',
          party_leader_image: process.env.PUBLIC_URL + '/images/nyamko-l.png',
          homepage: 'https://www.liberalerna.se',
          register_url: 'https://www.liberalerna.se/bli-medlem/',
          heroText: {
            breadText: 'Liberalerna (L), tidigare Folkpartiet liberalerna (FP) och dessförinnan Folkpartiet (FP), är ett socialliberalt politiskt parti i Sverige. Partiledare är sedan 2019 Nyamko Sabuni.',
            paragraphs: [
              {title: null,       text: 'Partiet bildades 1934 under namnet Folkpartiet genom en sammanslagning av riksdagspartierna Sveriges liberala parti och Frisinnade folkpartiet. Från 1940- till 1960-talet var de mestadels det största borgerliga partiet. Mellan 2004 och 2019 ingick Liberalerna tillsammans med Moderaterna, Kristdemokraterna och Centerpartiet i den borgerliga Alliansen.'},
              {title: null,       text: 'Partiledaren Bertil Ohlin förespråkade på 1940-talet idén om "fri ekonomi med socialt ansvar" eller socialliberalismen som partiets ideologi för att formulera ett reformvänligt alternativ till Socialdemokraterna i svensk politik. Sedan mitten på 1990-talet har partiet intagit en tydlig borgerlig profil för marknadsinslag i ekonomin, avregleringar och privatiseringar.[8] Liberalerna beskriver sig som ett liberalt idéparti med individen i centrum, som genom politik verkar för att människans valfrihet och egenmakt ökar och som genom politik skapar förutsättningar snarare än hinder. Liberalerna profilerar sig främst inom utbildnings-, jämställdhets-, integrations- och invandrings- samt Europafrågor.'},
              {title: null,       text: 'Partiet är efter riksdagsvalet 2018 storleksmässigt det sjunde största i Sveriges riksdag, endast Miljöpartiet har färre mandat. Liberalerna har traditionellt sitt starkaste stöd i storstadsområdena och på högskoleorter. I riksdagsvalet 2018 var partiet starkast i Stockholms kommuns valkrets följt av Göteborgs kommuns valkrets. Partiets starkaste kommuner i kommunalvalet var Landskrona kommun där partiet erhöll 34,2 procent av rösterna följt av Skinnskattebergs kommun med 21,5 procent. Överlag har partiet sitt största stöd hos storstads- och förortsbor, men även hos akademiker och unga. Statsvetare brukar ibland kalla Liberalerna för "partisystemets Hallsberg; en väntsal för partibytare", då partiet har mycket svårt att behålla sina väljare, men samtidigt utgör nästan var femte väljares andrahandsval.'},
              {title: null,       text: 'I Europaparlamentsvalet 2019 fick de 4,13 procentenheter av väljarnas röster och därmed ett mandat i parlamentet. Liberalerna utgör en del av det europeiska partiet Alliansen liberaler och demokrater för Europa (ALDE) och ingår i parlamentsgruppen med samma namn. I riksdagsvalet 2014 minskade partiet med 1,7 procentenheter till 5,4 procent.'},
              {title: null, text: 'Partiet har inte längre någon nomineringsgrupp i Svenska kyrkan, utan har ersatts av nätverket Fria liberaler i Svenska kyrkan (FiSK) vars nomineringsgrupp har samma namn.'},
            ]
          },
          currentPercent: 3.0,
          riksdagsval: {
            '1911': 40.2,
            '1914-03': 32.2,
            '1914-09': 26.9,
            '1917': 27.6,
            '1920': 21.8,
            '1921': 18.7,
            '1924': 13.0,
            '1928': 12.9,
            '1932': 9.8,
            '1936': 12.9,
            '1940': 12.0,
            '1944': 12.9,
            '1948': 22.8,
            '1952': 24.4,
            '1956': 23.8,
            '1958': 18.2,
            '1960': 17.5,
            '1964': 17.0,
            '1968': 14.3,
            '1970': 16.2,
            '1973': 9.4,
            '1976': 11.1,
            '1979': 10.6,
            '1982': 5.9,
            '1985': 14.2,
            '1988': 12.2,
            '1991': 9.1,
            '1994': 7.2,
            '1998': 4.7,
            '2002': 13.4,
            '2006': 7.5,
            '2010': 7.1,
            '2014': 5.4,
            '2018': 5.5,
          },
          opinionsundersökningar: {
            '2006-09': 7.5,
            '2006-10': 7.1,
            '2006-11': 6.8,
            '2006-12': 6.1,
            '2007-01': 6.4,
            '2007-02': 5.6,
            '2007-03': 6.1,
            '2007-04': 4.9,
            '2007-05': 5.2,
            '2007-06': 6.0,
            '2007-07': 6.1,
            '2007-08': 6.2,
            '2007-09': 6.8,
            '2007-10': 6.7,
            '2007-11': 7.2,
            '2007-12': 6.6,
            '2008-01': 6.6,
            '2008-02': 6.9,
            '2008-03': 7.8,
            '2008-04': 6.8,
            '2008-05': 7.8,
            '2008-06': 6.8,
            '2008-07': 6.6,
            '2008-08': 6.4,
            '2008-09': 6.8,
            '2008-10': 6.5,
            '2008-11': 6.4,
            '2008-12': 6.5,
            '2009-01': 5.9,
            '2009-02': 7.1,
            '2009-03': 6.8,
            '2009-04': 5.6,
            '2009-05': 6.8,
            '2009-06': 7.8,
            '2009-07': 8.1,
            '2009-08': 8.4,
            '2009-09': 6.0,
            '2009-10': 7.8,
            '2009-11': 6.2,
            '2009-12': 6.7,
            '2010-01': 7.3,
            '2010-02': 6.3,
            '2010-03': 7.2,
            '2010-04': 5.9,
            '2010-05': 7.2,
            '2010-06': 6.6,
            '2010-07': 6.8,
            '2010-08': 7.0,
            '2010-09': 7.1,
            '2010-10': 7.4,
            '2010-11': 8.2,
            '2010-12': 6.1,
            '2011-01': 6.6,
            '2011-02': 5.9,
            '2011-03': 7.3,
            '2011-04': 6.3,
            '2011-05': 6.2,
            '2011-06': 6.5,
            '2011-07': 6.0,
            '2011-08': 5.8,
            '2011-09': 5.1,
            '2011-10': 6.2,
            '2011-11': 5.1,
            '2011-12': 5.9,
            '2012-01': 5.8,
            '2012-02': 6.7,
            '2012-03': 5.5,
            '2012-04': 5.2,
            '2012-05': 4.7,
            '2012-06': 4.9,
            '2012-07': 6.6,
            '2012-08': 6.3,
            '2012-09': 7.2,
            '2012-10': 5.8,
            '2012-11': 5.9,
            '2012-12': 6.4,
            '2013-01': 6.4,
            '2013-02': 6.5,
            '2013-03': 5.4,
            '2013-04': 6.9,
            '2013-05': 5.5,
            '2013-06': 5.7,
            '2013-07': 5.6,
            '2013-08': 5.1,
            '2013-09': 6.6,
            '2013-10': 5.2,
            '2013-11': 5.3,
            '2013-12': 7.0,
            '2014-01': 5.8,
            '2014-02': 5.0,
            '2014-03': 5.1,
            '2014-04': 5.9,
            '2014-05': 6.0,
            '2014-06': 5.7,
            '2014-07': 6.5,
            '2014-08': 5.6,
            '2014-09': 5.9,
            '2014-10': 5.4,
            '2014-11': 5.6,
            '2014-12': 4.7,
            '2015-01': 4.8,
            '2015-02': 4.6,
            '2015-03': 4.9,
            '2015-04': 4.9,
            '2015-05': 4.9,
            '2015-06': 4.7,
            '2015-07': 4.7,
            '2015-08': 4.8,
            '2015-09': 4.9,
            '2015-10': 5.0,
            '2015-11': 5.4,
            '2015-12': 5.8,
            '2016-01': 5.4,
            '2016-02': 5.7,
            '2016-03': 4.6,
            '2016-04': 4.8,
            '2016-05': 4.2,
            '2016-06': 4.7,
            '2016-07': 4.5,
            '2016-08': 4.6,
            '2016-09': 4.9,
            '2016-10': 5.5,
            '2016-11': 5.4,
            '2016-12': 5.7,
            '2017-01': 5.4,
            '2017-02': 5.3,
            '2017-03': 5.5,
            '2017-04': 5.4,
            '2017-05': 5.5,
            '2017-06': 5.6,
            '2017-07': 5.9,
            '2017-08': 5.3,
            '2017-09': 5.1,
            '2017-10': 5.2,
            '2017-11': 4.6,
            '2017-12': 4.9,
            '2018-01': 4.4,
            '2018-02': 4.7,
            '2018-03': 4.6,
            '2018-04': 4.5,
            '2018-05': 4.4,
            '2018-06': 5.1,
            '2018-07': 4.9,
            '2018-08': 5.7,
            '2018-09': 5.8,
            '2018-10': 4.5,
            '2018-11': 4.1,
            '2018-12': 4.1,
            '2019-01': 3.8,
            '2019-02': 3.7,
            '2019-03': 3.6,
            '2019-04': 3.8,
            '2019-05': 3.7,
            '2019-06': 4.0,
            '2019-07': 5.7,
            '2019-08': 4.4,
            '2019-09': 4.0,
            '2019-10': 3.9,
            '2019-11': 3.4,
            '2019-12': 3.9,
            '2020-01': 3.4,
            '2020-02': 3.9,
            '2020-03': 3.9,
            '2020-04': 3.4,
            '2020-05': 3.0,
          }
        },
        'C': { 
          name: "centerpariet", 
          color: '#00873d',
          logo: process.env.PUBLIC_URL + '/images/centerpartiet.png',
          party_leader_image: process.env.PUBLIC_URL + '/images/annie-c.png',
          homepage: 'https://www.centerpartiet.se',
          register_url: 'https://www.centerpartiet.se/engagera-dig/bli-medlem',
          heroText: {
            breadText: 'Centerpartiet (C), även kallat Centern, är ett svenskt socialliberalt och grönt politiskt parti. På partiets valsedlar anges partibeteckningen Centerpartiet. Partiledare är sedan 2011 Annie Lööf.',
            paragraphs: [
              {title: null,               text: 'Centerpartiet antog sitt nuvarande namn 1957 som ersatte det tidigare Bondeförbundet som använts från 1913. På 1970-talet var Centerpartiet det ledande borgerliga partiet. Partiet ingick på 1950-talet i några socialdemokratiska koalitionsregeringar och stödde även 1995–1998 en socialdemokratisk regering. Sedan 2004 ingår Centerpartiet tillsammans med Moderaterna, Kristdemokraterna och Liberalerna i den borgerliga Alliansen som efter valet 2006 styrde Sverige 2006–2014 (se regeringen Reinfeldt).'},
              {title: null,               text: 'Partiet var ursprungligen landsbygdsinriktat och förespråkade sparsamhet med statens pengar. I takt med folkhemmets uppbyggnad, med urbanisering och landsbygdens avfolkning som följd, blev decentralisering partiets främsta ledord. Samtidigt fick liberala strömningar större genomslag i partiet och individen betonades. Sedan 2013 definierar partiet sin liberala ideologi som "social, decentralistisk och grön". Viktiga utgångspunkter för Centerpartiet är federalism, öppet samhälle med individen i fokus. Centerpartiet profilerar sig främst inom frågor som rör arbete och företagande, decentralisering och landsbygd, välfärd och miljö.'},
              {title: null,               text: 'Partiet är det fjärde största i riksdagen, med 31 mandat av 349, efter att ha fått 8,61 procent av rösterna i riksdagsvalet 2018. Centerpartiet har sitt starkaste stöd i Gotlands läns (17,20 % valet 2018, 13,41 % valet 2014) och Jämtlands läns (15,36 % valet 2018, 11,33 % valet 2014) riksdagsvalkretsar. Partiet är traditionellt sett starkare i kommuner och landsting, än på det nationella planet. Centerpartiet är det tredje största partiet i kyrkomötet.'},
              {title: null,               text: 'I Europaparlamentsvalet 2019 erhöll Centerpartiet 10,78 procent av väljarnas röster och fick därmed två mandat i parlamentet. I parlamentet är Centerpartiet en del gruppen Alliansen liberaler och demokrater för Europa (ALDE) och medlem av det europeiska partiet med samma namn. Internationellt är partiet medlem av Liberala internationalen.'},
              {title: null,               text: 'Partiet har en nomineringsgrupp i Svenska kyrkan med partibeteckningen Centerpartiet.'},
              {title: 'Lokalavdelningar', text: 'Centerpartiet finns lokalt över hela landet med distrikt i Blekinge, Dalarna, Fyrbodal, Gotland, Gävleborg, Göteborg, Halland, Jämtland, Jönköpings län, Kalmar län, Kronoberg, Norrbotten, Sjuhärad, Skaraborg, Skåne, Stockholm, Södermanland, Uppsala län, Värmland, Västerbotten, Västernorrland, Västmanland, Västra Götalands Västra, Örebro län och Östergötland. Utöver det är Centerpartiet representerade i kommuner och landsting.'},
            ]
          },
          currentPercent: 7.4,
          riksdagsval: {
            '1917': 5.3,
            '1920': 7.9,
            '1921': 11.1,
            '1924': 10.8,
            '1928': 11.2,
            '1932': 14.1,
            '1936': 14.3,
            '1940': 12.0,
            '1944': 13.6,
            '1948': 12.4,
            '1952': 10.7,
            '1956': 9.4,
            '1958': 12.7,
            '1960': 13.6,
            '1964': 13.2,
            '1968': 15.7,
            '1970': 19.9,
            '1973': 25.1,
            '1976': 24.1,
            '1979': 18.1,
            '1982': 15.5,
            '1985': 12.4,
            '1988': 11.3,
            '1991': 8.5,
            '1994': 7.7,
            '1998': 5.1,
            '2002': 6.2,
            '2006': 7.9,
            '2010': 6.6,
            '2014': 6.1,
            '2018': 8.6,
          },
          opinionsundersökningar: {
            '2006-09': 7.9,
            '2006-10': 7.1,
            '2006-11': 7.4,
            '2006-12': 6.7,
            '2007-01': 6.9,
            '2007-02': 6.8,
            '2007-03': 6.7,
            '2007-04': 6.4,
            '2007-05': 7.5,
            '2007-06': 6.7,
            '2007-07': 6.3,
            '2007-08': 5.9,
            '2007-09': 5.7,
            '2007-10': 6.1,
            '2007-11': 5.5,
            '2007-12': 6.0,
            '2008-01': 6.5,
            '2008-02': 5.9,
            '2008-03': 6.8,
            '2008-04': 6.3,
            '2008-05': 5.8,
            '2008-06': 6.5,
            '2008-07': 6.3,
            '2008-08': 6.2,
            '2008-09': 6.2,
            '2008-10': 6.1,
            '2008-11': 5.7,
            '2008-12': 5.9,
            '2009-01': 6.0,
            '2009-02': 5.2,
            '2009-03': 5.7,
            '2009-04': 5.7,
            '2009-05': 6.1,
            '2009-06': 5.4,
            '2009-07': 5.4,
            '2009-08': 5.5,
            '2009-09': 5.1,
            '2009-10': 5.0,
            '2009-11': 4.9,
            '2009-12': 4.9,
            '2010-01': 4.8,
            '2010-02': 4.9,
            '2010-03': 5.1,
            '2010-04': 5.7,
            '2010-05': 6.1,
            '2010-06': 5.4,
            '2010-07': 5.3,
            '2010-08': 5.5,
            '2010-09': 6.6,
            '2010-10': 6.1,
            '2010-11': 4.8,
            '2010-12': 5.0,
            '2011-01': 4.1,
            '2011-02': 4.0,
            '2011-03': 4.6,
            '2011-04': 4.2,
            '2011-05': 4.2,
            '2011-06': 5.0,
            '2011-07': 3.7,
            '2011-08': 5.4,
            '2011-09': 5.9,
            '2011-10': 5.8,
            '2011-11': 4.9,
            '2011-12': 6.1,
            '2012-01': 5.3,
            '2012-02': 4.3,
            '2012-03': 4.9,
            '2012-04': 4.2,
            '2012-05': 4.8,
            '2012-06': 4.4,
            '2012-07': 3.5,
            '2012-08': 4.3,
            '2012-09': 5.1,
            '2012-10': 3.9,
            '2012-11': 4.5,
            '2012-12': 2.9,
            '2013-01': 4.5,
            '2013-02': 3.4,
            '2013-03': 3.0,
            '2013-04': 4.0,
            '2013-05': 3.3,
            '2013-06': 3.4,
            '2013-07': 3.2,
            '2013-08': 3.8,
            '2013-09': 4.5,
            '2013-10': 4.2,
            '2013-11': 3.0,
            '2013-12': 3.5,
            '2014-01': 4.5,
            '2014-02': 4.3,
            '2014-03': 3.6,
            '2014-04': 3.7,
            '2014-05': 4.3,
            '2014-06': 5.3,
            '2014-07': 4.5,
            '2014-08': 4.7,
            '2014-09': 6.1,
            '2014-10': 5.7,
            '2014-11': 6.4,
            '2014-12': 6.1,
            '2015-01': 5.6,
            '2015-02': 6.4,
            '2015-03': 6.1,
            '2015-04': 6.3,
            '2015-05': 7.2,
            '2015-06': 6.8,
            '2015-07': 6.8,
            '2015-08': 6.8,
            '2015-09': 7.4,
            '2015-10': 7.3,
            '2015-11': 7.2,
            '2015-12': 7.2,
            '2016-01': 7.3,
            '2016-02': 6.8,
            '2016-03': 6.0,
            '2016-04': 6.3,
            '2016-05': 6.6,
            '2016-06': 7.3,
            '2016-07': 7.7,
            '2016-08': 7.4,
            '2016-09': 7.4,
            '2016-10': 7.8,
            '2016-11': 8.0,
            '2016-12': 8.8,
            '2017-01': 9.4,
            '2017-02': 11.4,
            '2017-03': 12.4,
            '2017-04': 12.3,
            '2017-05': 12.5,
            '2017-06': 12.1,
            '2017-07': 11.3,
            '2017-08': 11.9,
            '2017-09': 11.9,
            '2017-10': 10.5,
            '2017-11': 10.2,
            '2017-12': 9.6,
            '2018-01': 9.3,
            '2018-02': 9.9,
            '2018-03': 8.6,
            '2018-04': 9.2,
            '2018-05': 8.8,
            '2018-06': 9.1,
            '2018-07': 8.7,
            '2018-08': 8.3,
            '2018-09': 8.6,
            '2018-10': 8.9,
            '2018-11': 9.1,
            '2018-12': 7.7,
            '2019-01': 7.6,
            '2019-02': 7.8,
            '2019-03': 8.2,
            '2019-04': 8.0,
            '2019-05': 8.5,
            '2019-06': 9.0,
            '2019-07': 9.5,
            '2019-08': 8.3,
            '2019-09': 8.3,
            '2019-10': 8.3,
            '2019-11': 7.7,
            '2019-12': 8.1,
            '2020-01': 8.7,
            '2020-02': 8.3,
            '2020-03': 7.7,
            '2020-04': 7.5,
            '2020-05': 7.4,
          }
        },
        'KD': { 
          name: "kristdemokraterna", 
          color: '#005ea2',
          logo: process.env.PUBLIC_URL + '/images/kristdemokraterna.png',
          party_leader_image: process.env.PUBLIC_URL + '/images/ebba-kd.png',
          homepage: 'https://kristdemokraterna.se',
          register_url: 'https://kristdemokraterna.se/engagera-dig/bli-medlem/',
          heroText: {
            breadText: 'Kristdemokraterna (KD) är ett kristdemokratiskt och socialkonservativt politiskt parti i Sverige. På partiets valsedlar anges partibeteckningen Kristdemokraterna. Partiledare är sedan 25 april 2015 Ebba Busch Thor.',
            paragraphs: [
              {title: null,       text: 'Kristdemokraterna antog sitt nuvarande namn 1996 som ersatte Kristdemokratiska Samhällspartiet som använts från 1987 som då ersatte Kristen demokratisk samling som partiet bar från dess grundande 1964. Partiet fick in sin första representant i riksdagen 1985 i en valsamverkan med Centerpartiet och kom in under eget namn 1991. Åren 2004–2019 ingick Kristdemokraterna tillsammans med Moderaterna, Centerpartiet och Liberalerna i den borgerliga Alliansen.'},
              {title: null,       text: 'Kristdemokraterna beskrev sig ursprungligen som ett icke-socialistisk parti, men ville inte heller betecknas som borgerligt, men tog ställning för en borgerlig regering när de inledde ett valsamarbete 1985 med Thorbjörn Fälldins centerparti. Idag betecknar Kristdemokraterna sig som ett konservativt och "värdeorienterat idéparti" som "står för demokrati byggd på kristen människosyn och värdegrund" och hävdar sig till den kontinentala kristdemokratiska traditionen med idéer som personalism, människan och hennes ofullkomlighet, förvaltarskap, subsidiaritet och solidaritet. Partiet profilerar sig främst inom vård, omsorg, familje- och äldrefrågor, integration samt kriminalpolitik.'},
              {title: null,       text: 'Partiet är mandatmässigt det sjätte största i Sveriges riksdag och har cirka 21 600 medlemmar. Partiet har sitt starkaste stöd i valkretsarna i Jönköpings län, Stockholms län och Västra Götalands läns västra valkrets.'},
              {title: null,       text: 'I Europaparlamentsvalet 2019 erhöll Kristdemokraterna 8,62 procentenheter av väljarnas röster och har därmed två mandat i parlamentet. I parlamentet är Kristdemokraterna en del av Europeiska folkpartiets grupp (EPP). Partiet är medlem av det Europeiska folkpartiet och Kristdemokratiska internationalen.'},
              {title: null,       text: 'Partiet har inte längre någon nomineringsgrupp i Svenska kyrkan, utan har ersatts av nätverket Kristdemokrater för en levande kyrka (KR) med samma namn.'},
              {title: 'Organisation', text: 'Partiets organisation består av tre nivåer: partiavdelning, partidistrikt och riksorganisation. Medlemsinflytande utövas direkt av medlemmarna på partiavdelningsnivå och av de ombud som medlemmarna utser för partidistrikts- och riksorganisationsnivå. Vid alla tre nivåer finns en styrelse som väljs av respektive partiavdelningsårsmöte, partidistriktsårsmöte eller riksting. Därtill har de tre associerade förbunden rätt att utse varsin ledamot och ersättare på respektive organisationsnivå.'},
            ]
          },
          currentPercent: 6.4,
          riksdagsval: {
            '1964': 1.8,
            '1968': 1.5,
            '1970': 1.8,
            '1973': 1.8,
            '1976': 1.4,
            '1979': 1.4,
            '1982': 1.9,
            '1985': 12.4,
            '1988': 2.9,
            '1991': 7.1,
            '1994': 4.1,
            '1998': 11.8,
            '2002': 9.1,
            '2006': 6.6,
            '2010': 5.6,
            '2014': 4.6,
            '2018': 6.3,
          },
          opinionsundersökningar: {
            '2006-09': 6.6,
            '2006-10': 5.7,
            '2006-11': 5.6,
            '2006-12': 5.1,
            '2007-01': 4.8,
            '2007-02': 5.2,
            '2007-03': 4.1,
            '2007-04': 4.3,
            '2007-05': 4.4,
            '2007-06': 6.7,
            '2007-07': 5.8,
            '2007-08': 4.9,
            '2007-09': 3.9,
            '2007-10': 4.0,
            '2007-11': 4.7,
            '2007-12': 4.4,
            '2008-01': 4.2,
            '2008-02': 3.4,
            '2008-03': 3.0,
            '2008-04': 4.0,
            '2008-05': 4.7,
            '2008-06': 4.2,
            '2008-07': 4.3,
            '2008-08': 4.4,
            '2008-09': 3.3,
            '2008-10': 3.7,
            '2008-11': 3.8,
            '2008-12': 4.5,
            '2009-01': 4.1,
            '2009-02': 4.9,
            '2009-03': 4.2,
            '2009-04': 3.8,
            '2009-05': 4.5,
            '2009-06': 4.0,
            '2009-07': 4.2,
            '2009-08': 4.4,
            '2009-09': 4.2,
            '2009-10': 4.7,
            '2009-11': 4.1,
            '2009-12': 4.7,
            '2010-01': 4.4,
            '2010-02': 4.1,
            '2010-03': 4.6,
            '2010-04': 3.8,
            '2010-05': 4.6,
            '2010-06': 4.6,
            '2010-07': 5.9,
            '2010-08': 5.7,
            '2010-09': 5.6,
            '2010-10': 5.0,
            '2010-11': 4.4,
            '2010-12': 3.4,
            '2011-01': 3.7,
            '2011-02': 2.7,
            '2011-03': 3.5,
            '2011-04': 3.6,
            '2011-05': 3.5,
            '2011-06': 3.5,
            '2011-07': 3.3,
            '2011-08': 2.6,
            '2011-09': 3.4,
            '2011-10': 4.7,
            '2011-11': 3.2,
            '2011-12': 3.3,
            '2012-01': 2.9,
            '2012-02': 3.8,
            '2012-03': 3.6,
            '2012-04': 3.1,
            '2012-05': 3.4,
            '2012-06': 2.4,
            '2012-07': 3.7,
            '2012-08': 3.4,
            '2012-09': 3.0,
            '2012-10': 2.9,
            '2012-11': 3.7,
            '2012-12': 2.8,
            '2013-01': 2.9,
            '2013-02': 4.4,
            '2013-03': 3.4,
            '2013-04': 3.6,
            '2013-05': 2.9,
            '2013-06': 3.1,
            '2013-07': 3.0,
            '2013-08': 3.4,
            '2013-09': 3.7,
            '2013-10': 3.1,
            '2013-11': 3.9,
            '2013-12': 4.2,
            '2014-01': 3.6,
            '2014-02': 4.0,
            '2014-03': 3.3,
            '2014-04': 3.7,
            '2014-05': 3.9,
            '2014-06': 4.8,
            '2014-07': 4.2,
            '2014-08': 3.6,
            '2014-09': 4.6,
            '2014-10': 3.9,
            '2014-11': 3.7,
            '2014-12': 4.2,
            '2015-01': 4.2,
            '2015-02': 4.3,
            '2015-03': 4.2,
            '2015-04': 3.9,
            '2015-05': 3.4,
            '2015-06': 3.3,
            '2015-07': 3.4,
            '2015-08': 3.5,
            '2015-09': 3.6,
            '2015-10': 3.8,
            '2015-11': 3.8,
            '2015-12': 3.4,
            '2016-01': 3.5,
            '2016-02': 3.5,
            '2016-03': 3.6,
            '2016-04': 3.4,
            '2016-05': 3.8,
            '2016-06': 3.3,
            '2016-07': 3.5,
            '2016-08': 3.4,
            '2016-09': 3.3,
            '2016-10': 3.3,
            '2016-11': 3.1,
            '2016-12': 3.5,
            '2017-01': 3.1,
            '2017-02': 3.4,
            '2017-03': 3.1,
            '2017-04': 3.3,
            '2017-05': 3.3,
            '2017-06': 3.2,
            '2017-07': 3.0,
            '2017-08': 3.6,
            '2017-09': 3.5,
            '2017-10': 3.2,
            '2017-11': 3.3,
            '2017-12': 3.3,
            '2018-01': 3.1,
            '2018-02': 3.0,
            '2018-03': 3.9,
            '2018-04': 3.4,
            '2018-05': 3.3,
            '2018-06': 3.1,
            '2018-07': 3.5,
            '2018-08': 5.2,
            '2018-09': 6.1,
            '2018-10': 5.8,
            '2018-11': 5.9,
            '2018-12': 6.9,
            '2019-01': 7.8,
            '2019-02': 8.6,
            '2019-03': 10.8,
            '2019-04': 11.4,
            '2019-05': 10.8,
            '2019-06': 8.0,
            '2019-07': 9.2,
            '2019-08': 7.2,
            '2019-09': 6.6,
            '2019-10': 6.9,
            '2019-11': 7.2,
            '2019-12': 6.1,
            '2020-01': 6.7,
            '2020-02': 6.6,
            '2020-03': 6.8,
            '2020-04': 6.0,
            '2020-05': 6.4,
          }
        },
        'SD': { 
          name: "sverigedemokraterna", 
          color: '#ffda00',
          logo: process.env.PUBLIC_URL + '/images/sverigedemokraterna.png',
          party_leader_image: process.env.PUBLIC_URL + '/images/jimmie-sd.png',
          homepage: 'https://sd.se',
          register_url: 'https://sd.se/bli-medlem/',
          heroText: {
            breadText: 'Sverigedemokraterna (SD) är ett nationalistiskt och socialkonservativt politiskt parti i Sverige. På partiets valsedlar anges partibeteckningen Sverigedemokraterna. Partiledare är sedan 2005 Jimmie Åkesson.',
            paragraphs: [
              {title: null,       text: 'Sverigedemokraterna bildades 6 februari 1988 i Stockholm. Bland partiets grundare och tidiga medlemmar fanns flera personer tidigare verksamma i högerextrema och rasistiska partier och organisationer som Framstegspartiet, Sverigepartiet och Bevara Sverige Svenskt (BSS). I partiprogrammet antaget 1989 skriver partiet "Vi tror att en nationellt inriktad politik är lösningen på många av de problem som Sverige har idag."'},
              {title: null,       text: 'I slutet av 2011 ändrade partiet sin ideologiska profil genom att i första hand beskriva sig som "ett socialkonservativt parti med en nationalistisk grundsyn" från att tidigare ha beskrivit sig som "ett demokratiskt, nationalistiskt parti". Genomgripande frågor för partiet är enligt detsamma bekämpning av brottslighet och en restriktiv flykting- och invandringspolitik. I den statliga utredningen "Utredningen om ett effektivare arbete mot främlingsfientlighet" betecknades partiet 2012 som främlingsfientligt och att det även ger uttryck för en etnopluralistisk hållning.'},
              {title: null,       text: 'Partiet fick sina första mandat i kommunfullmäktige i valet 1991 och passerade riksdagens fyraprocentsspärr i valet 2010 med resultatet 5,7 procent. I Riksdagsvalet 2014 ökade partiet med 7,16 procentenheter och fick 12,86 procent av rösterna, och i Riksdagsvalet 2018 ökades stödet ytterligare med 4,68 procentenheter och de fick 17,53 procent av rösterna. Sverigedemokraterna är därmed Sveriges tredje största riksdagsparti. Övriga riksdagspartier har konsekvent vägrat samarbeta med Sverigedemokraterna. Därigenom står Sverigedemokraterna som ett eget parti utanför blockpolitiken och andra koalitioner på riks- och lokalnivå.'},
              {title: null,       text: 'Sverigedemokraterna har sitt starkaste stöd till övervägande del i traditionellt socialdemokratiskt styrda kommuner, koncentrerade till Sydsverige (framförallt Skåne). Svagast stöd har partiet i Västerbotten, trots socialdemokratiskt styrda kommuner.'},
              {title: null,       text: 'I Europaparlamentsvalet 2014 ökade Sverigedemokraterna med 6,6 procentenheter till 9,7 procent av väljarnas röster. Efter valet anslöt sig partiets två Europaparlamentariker till EU-parlamentsgruppen Gruppen Frihet och direktdemokrati i Europa (EFDD). Partiet motsätter sig överstatlighet och förespråkar att Sverige omförhandlar sitt EU-medlemskap för att få ett EES-medlemskap. I början av juli 2018 lämnade ledamöterna från Sverigedemokraterna gruppen för att istället ansluta sig till Gruppen Europeiska konservativa och reformister (ECR-gruppen).'},
              {title: null,       text: 'Partiet är också en nomineringsgrupp i Svenska kyrkan.'},
              {title: 'Partistyrelse', text: 'Sverigedemokraternas partistyrelse väljs vartannat år vid partiets stämma, kallad landsdagarna, som hålls på udda år under perioden mellan den 1 september och den 30 november. Partistyrelsen, som för två år valdes i Norrköping på Sverigedemokraternas landsdagar (tidigare användes benämningen riksårsmöte) i november 2017, följer enligt nedan.'},
            ]
          },
          currentPercent: 19.6,
          riksdagsval: {
            '1991': 0.1,
            '1994': 0.3,
            '1998': 0.4,
            '2002': 1.4,
            '2006': 2.9,
            '2010': 5.7,
            '2014': 12.9,
            '2018': 17.5,
          },
          opinionsundersökningar: {
            '2006-09': 2.5,
            '2006-10': 2.5,
            '2006-11': 2.6,
            '2006-12': 2.2,
            '2007-01': 2.4,
            '2007-02': 2.5,
            '2007-03': 2.3,
            '2007-04': 2.6,
            '2007-05': 2.7,
            '2007-06': 3.0,
            '2007-07': 3.1,
            '2007-08': 3.3,
            '2007-09': 2.3,
            '2007-10': 3.2,
            '2007-11': 2.3,
            '2007-12': 2.8,
            '2008-01': 2.9,
            '2008-02': 2.1,
            '2008-03': 3.0,
            '2008-04': 2.0,
            '2008-05': 2.9,
            '2008-06': 2.9,
            '2008-07': 3.4,
            '2008-08': 3.9,
            '2008-09': 3.3,
            '2008-10': 3.8,
            '2008-11': 3.0,
            '2008-12': 2.6,
            '2009-01': 3.7,
            '2009-02': 2.9,
            '2009-03': 3.3,
            '2009-04': 3.6,
            '2009-05': 3.6,
            '2009-06': 3.3,
            '2009-07': 3.3,
            '2009-08': 3.2,
            '2009-09': 3.7,
            '2009-10': 3.9,
            '2009-11': 4.7,
            '2009-12': 5.3,
            '2010-01': 4.1,
            '2010-02': 5.6,
            '2010-03': 3.6,
            '2010-04': 4.0,
            '2010-05': 3.4,
            '2010-06': 4.6,
            '2010-07': 3.6,
            '2010-08': 3.4,
            '2010-09': 5.7,
            '2010-10': 3.4,
            '2010-11': 4.9,
            '2010-12': 5.6,
            '2011-01': 5.7,
            '2011-02': 5.1,
            '2011-03': 5.7,
            '2011-04': 4.9,
            '2011-05': 7.1,
            '2011-06': 6.3,
            '2011-07': 4.6,
            '2011-08': 2.9,
            '2011-09': 4.8,
            '2011-10': 5.9,
            '2011-11': 6.0,
            '2011-12': 5.1,
            '2012-01': 6.0,
            '2012-02': 6.3,
            '2012-03': 5.2,
            '2012-04': 6.6,
            '2012-05': 4.6,
            '2012-06': 6.6,
            '2012-07': 6.0,
            '2012-08': 4.9,
            '2012-09': 6.3,
            '2012-10': 7.1,
            '2012-11': 9.0,
            '2012-12': 8.6,
            '2013-01': 9.2,
            '2013-02': 9.6,
            '2013-03': 11.0,
            '2013-04': 9.2,
            '2013-05': 9.9,
            '2013-06': 9.6,
            '2013-07': 11.6,
            '2013-08': 8.4,
            '2013-09': 10.1,
            '2013-10': 9.9,
            '2013-11': 11.9,
            '2013-12': 10.5,
            '2014-01': 9.8,
            '2014-02': 8.8,
            '2014-03': 10.0,
            '2014-04': 8.5,
            '2014-05': 8.2,
            '2014-06': 10.0,
            '2014-07': 9.2,
            '2014-08': 12.2,
            '2014-09': 12.1,
            '2014-10': 13.3,
            '2014-11': 13.3,
            '2014-12': 13.5,
            '2015-01': 14.7,
            '2015-02': 14.5,
            '2015-03': 15.1,
            '2015-04': 15.3,
            '2015-05': 16.1,
            '2015-06': 16.7,
            '2015-07': 17.8,
            '2015-08': 20.7,
            '2015-09': 21.4,
            '2015-10': 20.6,
            '2015-11': 21.2,
            '2015-12': 22.0,
            '2016-01': 21.5,
            '2016-02': 20.4,
            '2016-03': 19.9,
            '2016-04': 18.7,
            '2016-05': 18.8,
            '2016-06': 19.0,
            '2016-07': 20.9,
            '2016-08': 19.5,
            '2016-09': 19.8,
            '2016-10': 19.7,
            '2016-11': 19.9,
            '2016-12': 19.4,
            '2017-01': 18.7,
            '2017-02': 20.3,
            '2017-03': 20.6,
            '2017-04': 20.7,
            '2017-05': 19.8,
            '2017-06': 20.8,
            '2017-07': 23.3,
            '2017-08': 20.4,
            '2017-09': 19.6,
            '2017-10': 17.9,
            '2017-11': 17.1,
            '2017-12': 18.1,
            '2018-01': 17.6,
            '2018-02': 17.8,
            '2018-03': 18.4,
            '2018-04': 18.4,
            '2018-05': 20.7,
            '2018-06': 22.1,
            '2018-07': 22.5,
            '2018-08': 20.0,
            '2018-09': 18.5,
            '2018-10': 18.6,
            '2018-11': 19.6,
            '2018-12': 20.0,
            '2019-01': 19.2,
            '2019-02': 19.6,
            '2019-03': 18.3,
            '2019-04': 18.5,
            '2019-05': 18.3,
            '2019-06': 19.1,
            '2019-07': 18.0,
            '2019-08': 20.0,
            '2019-09': 22.1,
            '2019-10': 22.8,
            '2019-11': 24.4,
            '2019-12': 24.1,
            '2020-01': 25.7,
            '2020-02': 23.4,
            '2020-03': 22.7,
            '2020-04': 21.0,
            '2020-05': 19.6,
          }
        }
      }
}

const partyReducer = (state = initState, action) => {
    switch (action.type) {
        case '':
            return;
        default:
            return state;
    }
}

export default partyReducer