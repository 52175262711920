import React from 'react';

import './Contact.scss';
 
const Contact = () => {
    return (
        <div className="contact">

        </div>
    )
}

export default Contact;